import $ from "jquery";

let selectedFilters = [];
let selectedFiltersDiv = $(".selected-filters-filters");

function updateProjectCards() {
  if (selectedFilters.length === 0) {
    $(".case-studies-card").show();
    selectedFiltersDiv.append(
      '<div class="no-filter-selected">None Selected</div>'
    );
  } else {
    $(".no-filter-selected").remove();
    $(".case-studies-card").each(function () {
      let categories = $(this).find(".category").text().trim();
      let showCard = selectedFilters.some((filter) =>
        categories.includes(filter)
      );
      $(this).toggle(showCard);
    });
  }
}

function updateSelectedFilters() {
  selectedFiltersDiv.empty();
  selectedFilters.forEach((filter) => {
    selectedFiltersDiv.append(
      '<div class="selected-filter">' + filter + "</div>"
    );
  });
}

$(".filter-box_option").on("click", function () {
  let filterValue = $(this).text().trim();
  let $filterBox = $(this).closest(".filter-box");
  let $allOption = $filterBox.find(".filter-box_option-all");

  if (selectedFilters.includes(filterValue)) {
    selectedFilters = selectedFilters.filter(
      (filter) => filter !== filterValue
    );
    $(this).removeClass("selected");
    selectedFiltersDiv
      .find('.selected-filter:contains("' + filterValue + '")')
      .remove();
  } else {
    selectedFilters.push(filterValue);
    $(this).addClass("selected");
    selectedFiltersDiv.append(
      '<div class="selected-filter">' + filterValue + "</div>"
    );
  }

  if (selectedFilters.length === 0) {
    $allOption.addClass("selected");
    selectedFiltersDiv.append(
      '<div class="no-filter-selected">None Selected</div>'
    );
  } else {
    $allOption.removeClass("selected");
  }

  updateProjectCards();
});

$(".filter-box_option-all").on("click", function () {
  let $filterBox = $(this).closest(".filter-box");
  $(this).addClass("selected");
  $filterBox.find(".filter-box_option").removeClass("selected");
  selectedFilters = [];
  updateSelectedFilters();
  updateProjectCards();
});

$(".selected-filters-clear").on("click", function () {
  selectedFilters = [];
  $(".filter-box_option").removeClass("selected");
  updateSelectedFilters();
  updateProjectCards();
  $(".filter-box_option-all").addClass("selected");
});

$(".selected-filters-filters").on("click", ".selected-filter", function () {
  let filterValue = $(this).text().trim();
  selectedFilters = selectedFilters.filter((filter) => filter !== filterValue);
  $(this).remove();
  updateSelectedFilters();
  updateProjectCards();
  $('.filter-box_option:contains("' + filterValue + '")').removeClass(
    "selected"
  );
  if (selectedFilters.length === 0) {
    $(".filter-box_option-all").addClass("selected");
  }
});

$(".filter-box_title").on("click", function () {
  $(this).siblings(".filter-box_options").slideToggle("slow");
  $(this).find("svg").toggleClass("rotate-arrow");
});
